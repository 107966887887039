@media screen and (max-width: 600px) {
  .bannerHomeM {
    background-image: url("../multimedia/fondoMix.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: bottom;
    position: relative;
  }
  .textoBanner {
    width: 100%;
    padding: 40px 0;
    color: white;
    position: relative;
  }
  .nombreHome {
    font-size: 66px;
    margin: 0px;
    text-align: center;
  }

  .audiosMix {
    background-image: url("../multimedia/fondoDiseño.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: center;
    padding: 20px 0;
    position: relative;
  }
  .tipoMix {
    padding-top: 20px;
    margin: 0;
    font-size: 25px;
  }
  .wave {
    width: 90%;
    margin: 0 auto !important;
    display: block;
    margin-bottom: 20px;
  }
  .wave div wave {
    height: 100px !important;
  }
  .wave div div div svg,
  .wave div div div span {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .contenidoMix {
    background-color: white;
    border-radius: 10% 10% 20% 20%;
    width: 90%;
    margin: 0 5%;
    margin-bottom: 3%;
    text-align: center;
    position: relative;
    display: inline-block;
  }
  .textoMix {
    padding-bottom: 20px;
    display: inline-block;
    width: 90%;
    padding: 4% 0;
    text-align: justify;
  }
  .descMix {
    font-size: 18px;
  }
  .musicPlaying {
    font-size: 30px;
  }
  .volumeIcon {
    margin-right: 2%;
    font-size: 30px;
  }
  .volumeInput {
    width: 50%;
    vertical-align: top;
    margin-top: 10px;
  }
  .mixMasterIcons {
    display: inline-block;
    text-align: right;
    margin-left: 30%;
  }
  .mixMasterIcons2 {
    margin-left: 3%;
    width: 50%;
    display: inline-block;
    text-align: left;
  }
  canvas {
    width: 100%;
  }
  .abajoMix {
    border-top: 2px black solid;
    background-image: linear-gradient(to bottom, #ffffff, #a4bfd6);
    border-radius: 0 0 15% 15%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1199px) {
  .bannerHomeM {
    background-image: url("../multimedia/fondoMix.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: bottom;
    position: relative;
  }
  .textoBanner {
    width: 100%;
    padding: 80px 0;
    color: white;
    position: relative;
  }
  .nombreHome {
    font-size: 66px;
    margin: 0px;
    text-align: center;
  }

  .audiosMix {
    background-image: url("../multimedia/fondoDiseño.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: center;
    padding: 20px 0;
    position: relative;
  }
  .tipoMix {
    padding-top: 20px;
    margin: 0;
    font-size: 30px;
  }
  .wave {
    width: 90%;
    margin: 0 auto !important;
    display: block;
    margin-bottom: 20px;
  }
  .waveComp div wave {
    height: 110px !important;
  }
  .wave div div div svg,
  .wave div div div span {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .contenidoMix {
    background-color: white;
    border-radius: 10% 10% 20% 20%;
    width: 80%;
    margin: 0 10%;
    margin-bottom: 3%;
    text-align: center;
    position: relative;
    display: inline-block;
  }
  .textoMix {
    padding-bottom: 20px;
    display: inline-block;
    width: 80%;
    padding: 3% 0;
    text-align: justify;
  }
  .descMix {
    font-size: 25px;
  }
  .musicPlaying {
    font-size: 35px;
  }
  .volumeIcon {
    margin-right: 3%;
    font-size: 35px;
  }
  .volumeInput {
    width: 30%;
    vertical-align: top;
    margin-top: 14px;
  }
  .mixMasterIcons {
    display: inline-block;
    text-align: right;
    margin-left: 35%;
  }
  .mixMasterIcons2 {
    margin-left: 3%;
    width: 50%;
    display: inline-block;
    text-align: left;
  }
  canvas {
    width: 100%;
  }
  .abajoMix {
    border-top: 2px black solid;
    background-image: linear-gradient(to bottom, #ffffff, #a4bfd6);
    border-radius: 0 0 15% 15%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1595px) {
  .bannerHomeM {
    background-image: url("../multimedia/fondoMix.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: bottom;
    position: relative;
  }
  .textoBanner {
    width: 100%;
    padding: 80px 0;
    color: white;
    position: relative;
  }
  .nombreHome {
    font-size: 66px;
    margin: 0px;
    text-align: center;
  }

  .audiosMix {
    background-image: url("../multimedia/fondoDiseño.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: center;
    padding: 40px 0;
    position: relative;
  }
  .tipoMix {
    padding-top: 20px;
    margin: 0;
    font-size: 25px;
  }
  .wave {
    width: 90%;
    margin: 0 auto !important;
    display: block;
    margin-bottom: 20px;
  }
  .wave div div div svg,
  .wave div div div span {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .contenidoMix {
    background-color: white;
    border-radius: 10% 10% 20% 20%;
    width: 60%;
    margin: 0 20%;
    margin-bottom: 1.5%;
    text-align: center;
    position: relative;
    display: inline-block;
  }
  .textoMix {
    padding-bottom: 20px;
    display: inline-block;
    width: 50%;
    padding: 2% 0;
    text-align: justify;
    margin-right: 8%;
  }
  .musicPlaying {
    font-size: 30px;
  }
  .volumeIcon {
    margin-right: 3%;
    font-size: 35px;
  }
  .volumeInput {
    width: 20%;
    vertical-align: super;
  }
  .mixMasterIcons {
    display: inline-block;
    text-align: right;
    margin-left: 38%;
  }
  .mixMasterIcons2 {
    margin-left: 3%;
    width: 50%;
    display: inline-block;
    text-align: left;
  }
  canvas {
    width: 100%;
  }
  .abajoMix {
    border-top: 2px black solid;
    background-image: linear-gradient(to bottom, #ffffff, #a4bfd6);
    border-radius: 0 0 15% 15%;
  }
}

@media screen and (min-width: 1595px) {
  .bannerHomeM {
    background-image: url("../multimedia/fondoMix.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: bottom;
    position: relative;
  }
  .textoBanner {
    width: 100%;
    padding: 80px 0;
    color: white;
    position: relative;
  }
  .nombreHome {
    font-size: 66px;
    margin: 0px;
    text-align: center;
  }

  .audiosMix {
    background-image: url("../multimedia/fondoDiseño.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: center;
    padding: 40px 0;
    position: relative;
  }
  .tipoMix {
    padding-top: 20px;
    margin: 0;
    font-size: 25px;
  }
  .wave {
    width: 90%;
    margin: 0 auto !important;
    display: block;
    margin-bottom: 20px;
  }
  .wave div div div svg,
  .wave div div div span {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .contenidoMix {
    background-color: white;
    border-radius: 10% 10% 20% 20%;
    width: 60%;
    margin: 0 20%;
    margin-bottom: 1.5%;
    text-align: center;
    position: relative;
    display: inline-block;
  }
  .textoMix {
    padding-bottom: 20px;
    display: inline-block;
    width: 50%;
    padding: 2% 0;
    text-align: justify;
    margin-right: 8%;
  }
  .descMix {
    font-size: 20px;
  }
  .musicPlaying {
    font-size: 30px;
  }
  .volumeIcon {
    margin-right: 3%;
    font-size: 30px;
  }
  .volumeInput {
    width: 20%;
    vertical-align: super;
  }
  .mixMasterIcons {
    display: inline-block;
    text-align: right;
    margin-left: 38%;
  }
  .mixMasterIcons2 {
    margin-left: 3%;
    width: 50%;
    display: inline-block;
    text-align: left;
  }

  canvas {
    width: 100%;
  }

  .abajoMix {
    border-top: 2px black solid;
    background-image: linear-gradient(to bottom, #ffffff, #a4bfd6);
    border-radius: 0 0 15% 15%;
  }
}

/* Content styling */
/* fonts for the text */
@import url('https://fonts.googleapis.com/css?family=Merriweather:700|Roboto');
@import url('https://fonts.googleapis.com/css?family=Anton|Roboto');

@media screen and (max-width: 600px) {
  /* Text Color Variables */
$text-color: #444c5c;

/* Color Variables */
$main-bg: #f7f6f4;
$main-grey: #d8d5c4;
$main-green: #78a5a3;
$main-yellow-light: #e1b16a;
$main-yellow: #ffc300;
$main-blue: #444c5c;
$main-dark:rgb(51, 51, 51);
$main-red: #ffe593;
$main-white: #ffffff;
$main-black: #000a17;
$main-overlay:rgba(0, 0, 0, .3);

/* Lists */
$colors: $main-yellow $main-green $main-blue $main-red $main-dark;
$rainbowColors: $main-yellow $main-grey $main-blue $main-red $main-dark $main-red $main-blue $main-grey $main-yellow;
$fourColors: $main-yellow $main-green $main-blue $main-red;
$pages: "home" "about" "skills" "contact" "blog";

//Themes For Every Route
$theme-colors: (
    'bg': (
        'home': $main-dark,
        'about': $main-green,
        'skills': $main-blue,
        'contact': $main-red,
        'blog': $main-yellow,
    ),

    'text': (
        'home': $main-white,
        'about': $main-white,
        'skills': $main-white,
        'contact': $main-white,
        'blog': $main-white,
    ),
);

@function themeColor($key, $variant) {
    $map: map-get($theme-colors, $key);
    @return map-get($map, $variant);
}

@mixin centerer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin centererHorizontal {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin centererVertical {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}


.all, .all:before, .all:after {
  box-sizing: border-box;
}

$primary: #ffe593;
$secondary: #124;
$accent: #e72;

body {
  font: 400 16px Roboto, sans-serif;
  padding: 0;
  margin: 0;
  background-color: $main-dark;
}

.container {
  height: 100vh;
  align-items: center;
  justify-content: center;
  margin-top: -4%;
}

.card {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba($secondary,.06);
  overflow: hidden;
  width: 90%;
  margin: 0 auto;
}

.waves {
  height: 80px;
  background: linear-gradient(to bottom left, $primary, $accent);
}

.all h1 {
  margin: 1rem 1rem 0;
  font-size: 2rem;
  text-align: center;
}

.form {
  padding: 1rem;
}

.button {
  display: block;
  width: 100%;
  background-color: #ffe593;
  border: none;
  border-radius: 3px;
  padding: 1ch;
  font-size: 1rem;
  color: #fff;
  font-family: inherit;
}

@mixin input {
  display: block;
  width: 100%;
  border: 2px solid #eee;
  padding: 1ch;
  border-radius: 3px;
  transition: 150ms ease;
  outline: none;
  font-size: 1rem;
  font-family: inherit;
}

@mixin input-focus {
  border-color: #ffe593;
}

@mixin label {
  position: absolute;
  top: 1ch;
  left: 1ch;
  padding: 0 .5ch;
  pointer-events: none;
  transition: 150ms ease;
  color: #888;
}

@mixin label-focus {
  position: absolute;
  top: 0;
  font-size: 0.9em;
  color: #ffe593;
  font-weight: bold;
  transform: translateY(-50%);
  background-color: #fff;
  font-weight: 600;
}



.text-input {
  position: relative;
  margin-bottom: 1rem;
  label {
    @include label;
  }
  label.label-focus {
    @include label-focus;
  }
  input {
    @include input;
    width: 93%
  }
  input.input-focus {
    @include input-focus;
  }
}
.all{
  background-image: url("https://images.unsplash.com/photo-1598847873329-ed1608fb858b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30%;
}
.contactoInfoContainer{
  color:#eee;
  text-align: center;
  padding-top: 5%;
}
.mailIcon {

display: inline-block;
padding: 3vw;
}
.all .text-area {
  position: relative;
  width: 93%;
  margin-bottom: 1rem;
  label {
    @include label;
  }
  label.label-focus {
    @include label-focus;
  }
  textarea {
    @include input;
    height: 8em;
  }
  textarea.input-focus {
    @include input-focus;
  }
}

@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative;
  
  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0; height: 0;
    
    #{$vertical}: 0; 
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');
    
    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }
  
  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');
    
    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }
  
  &:hover {
    color: $hover;
    
    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }
    
    &::before { transition-delay: 0s, 0s, $duration; }
    
    &::after { transition-delay: 0s, $duration, 0s; }
  }
}

.draw-border {
  @include btn-border-drawing(#58afd1, #ffe593, 4px, bottom, right);
}

//=== Button styling, semi-ignore
.all .btncito {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1;
  font: 700 1.2rem 'Roboto Slab', sans-serif;
  padding: 0.8em 1em;
  letter-spacing: 0.05rem;
  text-align: center;
  
  
  &:focus { outline: 2px dotted #55d7dc; }
}
  .enviar{
    text-align: center;
  }
  .enviar div div div{
    margin: 10px auto;
  }

}

@media only screen and (min-width: 601px) and (max-width: 1199px) {
  /* Text Color Variables */
$text-color: #444c5c;

/* Color Variables */
$main-bg: #f7f6f4;
$main-grey: #d8d5c4;
$main-green: #78a5a3;
$main-yellow-light: #e1b16a;
$main-yellow: #ffc300;
$main-blue: #444c5c;
$main-dark:rgb(51, 51, 51);
$main-red: #ffe593;
$main-white: #ffffff;
$main-black: #000a17;
$main-overlay:rgba(0, 0, 0, .3);

/* Lists */
$colors: $main-yellow $main-green $main-blue $main-red $main-dark;
$rainbowColors: $main-yellow $main-grey $main-blue $main-red $main-dark $main-red $main-blue $main-grey $main-yellow;
$fourColors: $main-yellow $main-green $main-blue $main-red;
$pages: "home" "about" "skills" "contact" "blog";

//Themes For Every Route
$theme-colors: (
    'bg': (
        'home': $main-dark,
        'about': $main-green,
        'skills': $main-blue,
        'contact': $main-red,
        'blog': $main-yellow,
    ),

    'text': (
        'home': $main-white,
        'about': $main-white,
        'skills': $main-white,
        'contact': $main-white,
        'blog': $main-white,
    ),
);

@function themeColor($key, $variant) {
    $map: map-get($theme-colors, $key);
    @return map-get($map, $variant);
}

@mixin centerer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin centererHorizontal {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin centererVertical {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}


.all, .all:before, .all:after {
  box-sizing: border-box;
}

$primary: #ffe593;
$secondary: #124;
$accent: #e72;

body {
  font: 400 16px Roboto, sans-serif;
  padding: 0;
  margin: 0;
  background-color: $main-dark;
}

.container {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  margin-top: -4%;
}

.card {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba($secondary,.06);
  overflow: hidden;
  width: 80%;
  margin: 0 auto;
}

.waves {
  height: 80px;
  background: linear-gradient(to bottom left, $primary, $accent);
}

.all h1 {
  margin: 2rem 2rem 0;
  font-size: 2.5rem;
  text-align: center;
}

.form {
  padding: 2rem;
}

.button {
  display: block;
  width: 100%;
  background-color: #ffe593;
  border: none;
  border-radius: 3px;
  padding: 1ch;
  font-size: 1rem;
  color: #fff;
  font-family: inherit;
}

@mixin input {
  display: block;
  width: 100%;
  border: 2px solid #eee;
  padding: 1ch;
  border-radius: 3px;
  transition: 150ms ease;
  outline: none;
  font-size: 1rem;
  font-family: inherit;
}

@mixin input-focus {
  border-color: #ffe593;
}

@mixin label {
  position: absolute;
  top: 1ch;
  left: 1ch;
  padding: 0 .5ch;
  pointer-events: none;
  transition: 150ms ease;
  color: #888;
}

@mixin label-focus {
  position: absolute;
  top: 0;
  font-size: 0.9em;
  color: #ffe593;
  font-weight: bold;
  transform: translateY(-50%);
  background-color: #fff;
  font-weight: 600;
}



.text-input {
  position: relative;
  margin-bottom: 1rem;
  label {
    @include label;
    font-size: 1.5rem;
  }
  label.label-focus {
    @include label-focus;
  }
  input {
    @include input;
    font-size: 1.5rem;
    width: 95%
  }
  input.input-focus {
    @include input-focus;
  }
}
.all{
  background-image: url("https://images.unsplash.com/photo-1598847873329-ed1608fb858b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30%;
}
.contactoInfoContainer{
  color:#eee;
  text-align: center;
  padding-top: 5%;
}
.mailIcon {

display: inline-block;
padding: 3vw;
}
.all .text-area {
  position: relative;
  width: 95%;
  margin-bottom: 1rem;
  label {
    @include label;
    font-size: 1.3rem;
  }
  label.label-focus {
    @include label-focus;
  }
  textarea {
    @include input;
    height: 8em;
    font-size: 1.3rem;
  }
  textarea.input-focus {
    @include input-focus;
  }
}

@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative;
  
  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0; height: 0;
    
    #{$vertical}: 0; 
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');
    
    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }
  
  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');
    
    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }
  
  &:hover {
    color: $hover;
    
    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }
    
    &::before { transition-delay: 0s, 0s, $duration; }
    
    &::after { transition-delay: 0s, $duration, 0s; }
  }
}

.draw-border {
  @include btn-border-drawing(#58afd1, #ffe593, 4px, bottom, right);
}

//=== Button styling, semi-ignore
.all .btncito {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1;
  font: 700 1.2rem 'Roboto Slab', sans-serif;
  padding: 1em 1em;
  letter-spacing: 0.05rem;
  width: 20vw;
  text-align: center;
  
  
  &:focus { outline: 2px dotted #55d7dc; }
}
  .enviar{
    text-align: center;
  }
  .enviar div div div{
    margin: 20px auto;
  }

}

@media screen and (min-width: 1200px) and (max-width: 1595px) {
  /* Text Color Variables */
$text-color: #444c5c;

/* Color Variables */
$main-bg: #f7f6f4;
$main-grey: #d8d5c4;
$main-green: #78a5a3;
$main-yellow-light: #e1b16a;
$main-yellow: #ffc300;
$main-blue: #444c5c;
$main-dark:rgb(51, 51, 51);
$main-red: #ffe593;
$main-white: #ffffff;
$main-black: #000a17;
$main-overlay:rgba(0, 0, 0, .3);

/* Lists */
$colors: $main-yellow $main-green $main-blue $main-red $main-dark;
$rainbowColors: $main-yellow $main-grey $main-blue $main-red $main-dark $main-red $main-blue $main-grey $main-yellow;
$fourColors: $main-yellow $main-green $main-blue $main-red;
$pages: "home" "about" "skills" "contact" "blog";

//Themes For Every Route
$theme-colors: (
    'bg': (
        'home': $main-dark,
        'about': $main-green,
        'skills': $main-blue,
        'contact': $main-red,
        'blog': $main-yellow,
    ),

    'text': (
        'home': $main-white,
        'about': $main-white,
        'skills': $main-white,
        'contact': $main-white,
        'blog': $main-white,
    ),
);

@function themeColor($key, $variant) {
    $map: map-get($theme-colors, $key);
    @return map-get($map, $variant);
}

@mixin centerer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin centererHorizontal {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin centererVertical {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}


.all, .all:before, .all:after {
  box-sizing: border-box;
}

$primary: #ffe593;
$secondary: #124;
$accent: #e72;

body {
  font: 400 16px Roboto, sans-serif;
  padding: 0;
  margin: 0;
  background-color: $main-dark;
}

.container {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.card {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba($secondary,.06);
  overflow: hidden;
}

.waves {
  height: 80px;
  background: linear-gradient(to bottom left, $primary, $accent);
}

.all h1 {
  margin: 2rem 2rem 0;
  font-size: 1.62rem;
}

.form {
  padding: 2rem;
}

.button {
  display: block;
  width: 100%;
  background-color: #ffe593;
  border: none;
  border-radius: 3px;
  padding: 1ch;
  font-size: 1rem;
  color: #fff;
  font-family: inherit;
}

@mixin input {
  display: block;
  width: 100%;
  border: 2px solid #eee;
  padding: 1ch;
  border-radius: 3px;
  transition: 150ms ease;
  outline: none;
  font-size: 1rem;
  font-family: inherit;
}

@mixin input-focus {
  border-color: #ffe593;
}

@mixin label {
  position: absolute;
  top: 1ch;
  left: 1ch;
  padding: 0 .5ch;
  pointer-events: none;
  transition: 150ms ease;
  color: #888;
}

@mixin label-focus {
  position: absolute;
  top: 0;
  font-size: 0.9em;
  color: #ffe593;
  font-weight: bold;
  transform: translateY(-50%);
  background-color: #fff;
  font-weight: 600;
}



.text-input {
  position: relative;
  margin-bottom: 1rem;
  label {
    @include label;
  }
  label.label-focus {
    @include label-focus;
  }
  input {
    @include input;
  }
  input.input-focus {
    @include input-focus;
  }
}
.all{
  background-image: url("https://images.unsplash.com/photo-1598847873329-ed1608fb858b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80");
  background-size: cover;
}
.contactoInfoContainer{
  color:#eee;
  margin-right: 8vw;
}
.mailIcon {

display: inline-block;
padding: 3vw;
}
.all .text-area {
  position: relative;
  width: 45vw;
  margin-bottom: 1rem;
  label {
    @include label;
  }
  label.label-focus {
    @include label-focus;
  }
  textarea {
    @include input;
    height: 8em;
  }
  textarea.input-focus {
    @include input-focus;
  }
}

@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative;
  
  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0; height: 0;
    
    #{$vertical}: 0; 
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');
    
    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }
  
  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');
    
    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }
  
  &:hover {
    color: $hover;
    
    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }
    
    &::before { transition-delay: 0s, 0s, $duration; }
    
    &::after { transition-delay: 0s, $duration, 0s; }
  }
}

.draw-border {
  @include btn-border-drawing(#58afd1, #ffe593, 4px, bottom, right);
}

//=== Button styling, semi-ignore
.all .btncito {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1;
  font: 700 1.2rem 'Roboto Slab', sans-serif;
  padding: 1em 1em;
  letter-spacing: 0.05rem;
  width: 20vw;
  
  
  &:focus { outline: 2px dotted #55d7dc; }
}
}

@media screen and (min-width: 1595px) {
  /* Text Color Variables */
$text-color: #444c5c;

/* Color Variables */
$main-bg: #f7f6f4;
$main-grey: #d8d5c4;
$main-green: #78a5a3;
$main-yellow-light: #e1b16a;
$main-yellow: #ffc300;
$main-blue: #444c5c;
$main-dark:rgb(51, 51, 51);
$main-red: #ffe593;
$main-white: #ffffff;
$main-black: #000a17;
$main-overlay:rgba(0, 0, 0, .3);

/* Lists */
$colors: $main-yellow $main-green $main-blue $main-red $main-dark;
$rainbowColors: $main-yellow $main-grey $main-blue $main-red $main-dark $main-red $main-blue $main-grey $main-yellow;
$fourColors: $main-yellow $main-green $main-blue $main-red;
$pages: "home" "about" "skills" "contact" "blog";

//Themes For Every Route
$theme-colors: (
    'bg': (
        'home': $main-dark,
        'about': $main-green,
        'skills': $main-blue,
        'contact': $main-red,
        'blog': $main-yellow,
    ),

    'text': (
        'home': $main-white,
        'about': $main-white,
        'skills': $main-white,
        'contact': $main-white,
        'blog': $main-white,
    ),
);

@function themeColor($key, $variant) {
    $map: map-get($theme-colors, $key);
    @return map-get($map, $variant);
}

@mixin centerer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin centererHorizontal {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin centererVertical {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}


.all, .all:before, .all:after {
  box-sizing: border-box;
}

$primary: #ffe593;
$secondary: #124;
$accent: #e72;

body {
  font: 400 16px Roboto, sans-serif;
  padding: 0;
  margin: 0;
  background-color: $main-dark;
}

.container {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.card {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba($secondary,.06);
  overflow: hidden;
}

.waves {
  height: 80px;
  background: linear-gradient(to bottom left, $primary, $accent);
}

.all h1 {
  margin: 2rem 2rem 0;
  font-size: 1.62rem;
}

.form {
  padding: 2rem;
}

.button {
  display: block;
  width: 100%;
  background-color: #ffe593;
  border: none;
  border-radius: 3px;
  padding: 1ch;
  font-size: 1rem;
  color: #fff;
  font-family: inherit;
}

@mixin input {
  display: block;
  width: 100%;
  border: 2px solid #eee;
  padding: 1ch;
  border-radius: 3px;
  transition: 150ms ease;
  outline: none;
  font-size: 1rem;
  font-family: inherit;
}

@mixin input-focus {
  border-color: #ffe593;
}

@mixin label {
  position: absolute;
  top: 1ch;
  left: 1ch;
  padding: 0 .5ch;
  pointer-events: none;
  transition: 150ms ease;
  color: #888;
}

@mixin label-focus {
  position: absolute;
  top: 0;
  font-size: 0.9em;
  color: #ffe593;
  font-weight: bold;
  transform: translateY(-50%);
  background-color: #fff;
  font-weight: 600;
}



.text-input {
  position: relative;
  margin-bottom: 1rem;
  label {
    @include label;
  }
  label.label-focus {
    @include label-focus;
  }
  input {
    @include input;
  }
  input.input-focus {
    @include input-focus;
  }
}
.all{
  background-image: url("https://images.unsplash.com/photo-1598847873329-ed1608fb858b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80");
  background-size: cover;
}
.contactoInfoContainer{
  color:#eee;
  margin-right: 8vw;
}
.mailIcon {

display: inline-block;
padding: 3vw;
}
.all .text-area {
  position: relative;
  width: 45vw;
  margin-bottom: 1rem;
  label {
    @include label;
  }
  label.label-focus {
    @include label-focus;
  }
  textarea {
    @include input;
    height: 8em;
  }
  textarea.input-focus {
    @include input-focus;
  }
}

@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative;
  
  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0; height: 0;
    
    #{$vertical}: 0; 
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');
    
    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }
  
  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');
    
    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }
  
  &:hover {
    color: $hover;
    
    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }
    
    &::before { transition-delay: 0s, 0s, $duration; }
    
    &::after { transition-delay: 0s, $duration, 0s; }
  }
}

.draw-border {
  @include btn-border-drawing(#58afd1, #ffe593, 4px, bottom, right);
}

//=== Button styling, semi-ignore
.all .btncito {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1;
  font: 700 1.2rem 'Roboto Slab', sans-serif;
  padding: 1em 1em;
  letter-spacing: 0.05rem;
  width: 20vw;
  
  
  &:focus { outline: 2px dotted #55d7dc; }
}
}



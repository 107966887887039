@media screen and (max-width: 600px) {
  .nav {
    background-color: #f4f5f7;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid #343a4023;
    display: inline-block;
    position: relative;
    z-index: 10;
    padding: 8px 0;
  }
  .navCompu {
    display: none;
  }

  .nombre {
    display: inline-block;
    color: black;
    text-decoration: none;
    width: 60vw;
    font-size: 28px;
  }
  .nombre a {
    padding-left: 20%;
  }

  .subMenu {
    display: inline-block;
    padding-left: 10vw;
    width: 20vw;
    font-size: 2em;
    margin: 0;
  }

  .listaNav {
    list-style-type: none;
    display: inline-block;
  }

  .sublistaNav {
    list-style-type: none;
    padding: 10px 15px;
    font-size: 25px;
  }
  .navCompu .opcion {
    display: inline-block;
    margin: 0 10px;
  }
  .dropdownMenu.inactive {
    display: none;
  }
  .dropdownMenu.active {
    display: block;
  }
  .dropdownMenu {
    padding-left: 0;
    right: 0;
    z-index: 20;
    position: absolute;
    background-color: #f4f5f7;
    border: 1px solid #343a4023;
    animation: SubMenuNavbar 0.6s linear;
    border: 2px solid gray;
    border-radius: 10% 0 0 10%;
  }
  @keyframes SubMenuNavbar {
    0% {
      transform: translateX(60vw);
    }
    100% {
      transform: translateX(0vw);
    }
  }

  .opcion,
  .subopcion {
    cursor: pointer;
    color: black;
    text-decoration: none;
  }
  .opcion:hover,
  .subopcion:hover {
    color: rgb(42, 88, 238);
  }
}

@media only screen and (min-width: 601px) and (max-width: 1199px) {
  .nav {
    background-color: #f4f5f7;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid #343a4023;
    display: inline-block;
    position: relative;
    z-index: 10;
    padding: 8px 0;
  }
  .navCompu {
    display: none;
  }

  .nombre {
    display: inline-block;
    color: black;
    text-decoration: none;
    width: 70vw;
    font-size: 35px;
  }
  .nombre a {
    padding-left: 20%;
  }

  .subMenu {
    display: inline-block;
    padding-left: 10vw;
    width: 20vw;
    font-size: 2em;
    margin: 0;
  }

  .listaNav {
    list-style-type: none;
    display: inline-block;
  }

  .sublistaNav {
    list-style-type: none;
    padding: 15px 20px;
    font-size: 35px;
  }
  .navCompu .opcion {
    display: inline-block;
    margin: 0 10px;
  }
  .dropdownMenu.inactive {
    display: none;
  }
  .dropdownMenu.active {
    display: block;
  }
  .dropdownMenu {
    padding-left: 0;
    right: 0;
    z-index: 20;
    position: absolute;
    background-color: #f4f5f7;
    border: 1px solid #343a4023;
    animation: SubMenuNavbar 0.6s linear;
    border: 2px solid gray;
    border-radius: 10% 0 0 10%;
  }
  @keyframes SubMenuNavbar {
    0% {
      transform: translateX(40vw);
    }
    100% {
      transform: translateX(0vw);
    }
  }

  .opcion,
  .subopcion {
    cursor: pointer;
    color: black;
    text-decoration: none;
  }
  .opcion:hover,
  .subopcion:hover {
    color: rgb(42, 88, 238);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1595px) {
  .nav {
    background-color: #f4f5f7;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid #343a4023;
    display: inline-block;
    position: relative;
    z-index: 10;
    padding: 8px 0;
  }
  .navCel {
    display: none;
  }
  .nombre {
    display: inline-block;
    color: black;
    text-decoration: none;
    width: 40vw;
    font-size: 24px;
  }
  .nombre a {
    padding-left: 20%;
  }

  .subMenu {
    display: inline-block;
    padding-left: 9vw;
    width: 46vw;
    font-size: 1em;
  }

  .listaNav {
    list-style-type: none;
    display: inline-block;
  }

  .sublistaNav {
    list-style-type: none;
    padding: 15px 20px;
  }
  .navCompu .opcion {
    display: inline-block;
    margin: 0 10px;
  }
  .dropdownMenu.inactive {
    display: none;
  }
  .dropdownMenu.active {
    display: block;
  }
  .dropdownMenu {
    padding-left: 0;
    left: 53vw;
    z-index: 20;
    position: absolute;
    background-color: white;
    border: 1px solid #343a4023;
  }

  .opcion,
  .subopcion {
    cursor: pointer;
    color: black;
    text-decoration: none;
  }
  .opcion:hover,
  .subopcion:hover {
    color: rgb(42, 88, 238);
  }
}

@media screen and (min-width: 1595px) {
  .nav {
    background-color: #f4f5f7;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid #343a4023;
    display: inline-block;
    position: relative;
    z-index: 10;
    padding: 8px 0;
  }
  .navCel {
    display: none;
  }
  .nombre {
    display: inline-block;
    color: black;
    text-decoration: none;
    width: 40vw;
    font-size: 28px;
  }
  .nombre a {
    padding-left: 20%;
  }

  .subMenu {
    display: inline-block;
    padding-left: 10vw;
    width: 40vw;
    font-size: 1.2em;
  }

  .listaNav {
    list-style-type: none;
    display: inline-block;
  }

  .sublistaNav {
    list-style-type: none;
    padding: 15px 20px;
  }
  .navCompu .opcion {
    display: inline-block;
    margin: 0 10px;
  }
  .dropdownMenu.inactive {
    display: none;
  }
  .dropdownMenu.active {
    display: block;
  }
  .dropdownMenu {
    padding-left: 0;
    left: 52vw;
    z-index: 20;
    position: absolute;
    background-color: white;
    border: 1px solid #343a4023;
  }

  .opcion,
  .subopcion {
    cursor: pointer;
    color: black;
    text-decoration: none;
  }
  .opcion:hover,
  .subopcion:hover {
    color: rgb(42, 88, 238);
  }
}

@media screen and (max-width: 600px) {
  .CompCompu {
    display: none !important;
  }
  .bannerHomeC {
    background-image: url("../multimedia/ondas.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: 0%;
    position: relative;
  }
  .textoBanner {
    width: 100%;
    padding: 80px 0;
    color: white;
    position: relative;
  }
  .nombreHome {
    font-size: 66px;
    margin: 0px;
    text-align: center;
  }

  .audiosComposicion {
    background-image: url("../multimedia/fondoComp.PNG");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: center;
    padding: 20px 0;
    position: relative;
  }
  .tipoComposicion {
    padding-top: 20px;
    margin: 0;
    font-size: 25px;
  }
  .waveComp {
    width: 90%;
    margin: 0 auto !important;
    display: block;
    margin-bottom: 20px;
  }
  .waveComp div wave {
    height: 100px !important;
  }
  .waveComp div div div svg,
  .waveComp div div div span {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .contenidoComposicion {
    background-color: white;
    border-radius: 10% 10% 20% 20%;
    width: 90%;
    margin: 0 5%;
    margin-bottom: 3%;
    text-align: center;
    position: relative;
    display: inline-block;
  }
  .textoComposicion {
    padding-bottom: 20px;
  }

  .descComposicion {
    font-size: 18px;
  }
  .musicPlaying {
    font-size: 30px;
  }
  .volumeIcon {
    margin-right: 3%;
    font-size: 30px;
  }
  .volumeInputComp {
    width: 50%;
    vertical-align: top;
    margin-top: 10px;
  }
  .ComposicionMasterIcons {
    display: inline-block;
    text-align: right;
    margin-left: 30%;
  }
  .ComposicionMasterIcons2 {
    margin-left: 3%;
    width: 50%;
    display: inline-block;
    text-align: left;
  }

  canvas {
    width: 100%;
  }

  .abajoComposicion {
    border-top: 2px black solid;
    background-image: linear-gradient(to bottom, #ffffff, #a4bfd6);
    border-radius: 0 0 15% 15%;
  }

  .textoComposicion {
    display: inline-block;
    width: 50%;
    padding: 2% 0;
    padding-bottom: 4%;
    text-align: justify;
    margin-left: 2%;
  }
  .textoComposicion2 {
    display: inline-block;
    width: 90%;
    padding: 4% 0;
    text-align: justify;
  }

  .añadidoComposicion {
    display: inline-block;
    width: 40%;
    vertical-align: top;
    padding-top: 6%;
    margin-left: 2.5%;
  }

  .imgPDF {
    width: 100%;
  }

  /* .juegoPDF {
    color: black;
    background-color: rgba(161, 161, 161, 0.575);
  }
  .juegoPDF p {
    text-decoration: none;
    color: black;
    margin: 0;
    background-color: rgba(161, 161, 161, 0.575);
    font-size: 18px;
    padding-bottom: 8px;
  }
  .juegoPDF p:hover,
  .juegoPDF:hover,
  .juegoPDF:hover > .p {
    background-color: rgba(161, 161, 161, 0.74);
  } */

  .apartadoComposicion {
    width: 50%;
    display: inline-block;
    position: relative;
    height: 19.6vw !important;
  }

  .apartadoComposicion2 {
    width: 90%;
    position: relative;
    margin-left: 5%;
  }
  .filaComposicion {
    margin-bottom: 3%;
  }
  .textComposicion {
    text-align: center;
  }

  .aptD2 {
    background-image: linear-gradient(to bottom, #ffffff, #a4bfd6);
    border-radius: 13% 13% 0 0;
    opacity: 0.8;
  }
  .tituloVidComposicion {
    margin: 0;
    margin-bottom: 25px 0;
    padding-top: 4%;
    font-size: 25px;
    opacity: 1;
  }
  .descComposicion2 {
    padding: 2% 10%;
    font-size: 20px;
    opacity: 1;
    margin: 0;
  }
  .playerVidComposicion {
    height: 50.625vw !important;
    width: auto !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1199px) {
  .CompCompu {
    display: none !important;
  }
  .bannerHomeC {
    background-image: url("../multimedia/ondas.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: 00%;
    position: relative;
  }
  .textoBanner {
    width: 100%;
    padding: 80px 0;
    color: white;
    position: relative;
  }
  .nombreHome {
    font-size: 66px;
    margin: 0px;
    text-align: center;
  }

  .audiosComposicion {
    background-image: url("../multimedia/fondoComp.PNG");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: center;
    padding: 20px 0;
    position: relative;
  }
  .tipoComposicion {
    padding-top: 20px;
    margin: 0;
    font-size: 30px;
  }
  .waveComp {
    width: 90%;
    margin: 0 auto !important;
    display: block;
    margin-bottom: 20px;
  }
  .waveComp div wave {
    height: 110px !important;
  }
  .waveComp div div div svg,
  .waveComp div div div span {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .contenidoComposicion {
    background-color: white;
    border-radius: 10% 10% 20% 20%;
    width: 80%;
    margin: 0 10%;
    margin-bottom: 3%;
    text-align: center;
    position: relative;
    display: inline-block;
  }
  .textoComposicion {
    padding-bottom: 20px;
  }

  .descComposicion {
    font-size: 25px;
  }
  .musicPlaying {
    font-size: 35px;
  }
  .volumeIcon {
    margin-right: 3%;
    font-size: 35px;
  }
  .volumeInputComp {
    width: 30%;
    vertical-align: top;
    margin-top: 14px;
  }
  .ComposicionMasterIcons {
    display: inline-block;
    text-align: right;
    margin-left: 35%;
  }
  .ComposicionMasterIcons2 {
    margin-left: 3%;
    width: 50%;
    display: inline-block;
    text-align: left;
  }

  canvas {
    width: 100%;
  }

  .abajoComposicion {
    border-top: 2px black solid;
    background-image: linear-gradient(to bottom, #ffffff, #a4bfd6);
    border-radius: 0 0 15% 15%;
  }

  .textoComposicion {
    display: inline-block;
    width: 50%;
    padding: 3% 0;
    text-align: justify;
    margin-right: 3%;
  }
  .textoComposicion2 {
    display: inline-block;
    width: 80%;
    padding: 3% 0;
    text-align: justify;
  }

  .añadidoComposicion {
    display: inline-block;
    width: 35%;
    vertical-align: top;
    padding: 15px;
  }

  .imgPDF {
    width: 100%;
  }

  /* .juegoPDF {
    color: black;
    background-color: rgba(161, 161, 161, 0.575);
  }
  .juegoPDF p {
    text-decoration: none;
    color: black;
    margin: 0;
    background-color: rgba(161, 161, 161, 0.575);
    font-size: 20px;
    padding-bottom: 10px;
  }
  .juegoPDF p:hover,
  .juegoPDF:hover,
  .juegoPDF:hover > .p {
    background-color: rgba(161, 161, 161, 0.74);
  } */

  .apartadoComposicion {
    width: 50%;
    display: inline-block;
    position: relative;
    height: 19.6vw !important;
  }

  .apartadoComposicion2 {
    width: 80%;
    position: relative;
    margin-left: 10%;
  }
  .filaComposicion {
    margin-bottom: 3%;
  }
  .textComposicion {
    text-align: center;
  }

  .aptD2 {
    background-image: linear-gradient(to bottom, #ffffff, #a4bfd6);
    border-radius: 13% 13% 0 0;
    opacity: 0.8;
  }
  .tituloVidComposicion {
    margin: 0;
    margin-bottom: 25px 0;
    padding-top: 4%;
    font-size: 40px;
    opacity: 1;
  }
  .descComposicion2 {
    padding: 2% 10%;
    font-size: 30px;
    opacity: 1;
    margin: 0;
  }
  .playerVidComposicion {
    height: 50.625vw !important;
    width: auto !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1595px) {
  .CompCel {
    display: none !important;
  }
  .bannerHomeC {
    background-image: url("../multimedia/ondas.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: 50%;
    position: relative;
  }
  .textoBanner {
    width: 100%;
    padding: 80px 0;
    color: white;
    position: relative;
  }
  .nombreHome {
    font-size: 66px;
    margin: 0px;
    text-align: center;
  }

  .audiosComposicion {
    background-image: url("../multimedia/fondoComp.PNG");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: center;
    padding: 40px 0;
    position: relative;
  }
  .tipoComposicion {
    padding-top: 20px;
    margin: 0;
    font-size: 25px;
  }
  .waveComp {
    width: 90%;
    margin: 0 auto !important;
    display: block;
    margin-bottom: 20px;
  }
  .waveComp div div div svg,
  .waveComp div div div span {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .contenidoComposicion {
    background-color: white;
    border-radius: 10% 10% 20% 20%;
    width: 60%;
    margin: 0 20%;
    margin-bottom: 1.5%;
    text-align: center;
    position: relative;
    display: inline-block;
  }
  .textoComposicion {
    padding-bottom: 20px;
  }

  .descComposicion {
    font-size: 20px;
  }
  .musicPlaying {
    font-size: 30px;
  }
  .volumeIcon {
    margin-right: 3%;
    font-size: 30px;
  }
  .volumeInputComp {
    width: 20%;
    vertical-align: super;
  }
  .ComposicionMasterIcons {
    display: inline-block;
    text-align: right;
    margin-left: 38%;
  }
  .ComposicionMasterIcons2 {
    margin-left: 3%;
    width: 50%;
    display: inline-block;
    text-align: left;
  }

  canvas {
    width: 100%;
  }

  .abajoComposicion {
    border-top: 2px black solid;
    background-image: linear-gradient(to bottom, #ffffff, #a4bfd6);
    border-radius: 0 0 15% 15%;
  }

  .textoComposicion {
    display: inline-block;
    width: 50%;
    margin-top: 3%;
    text-align: justify;
    margin-right: 8%;
  }
  .textoComposicion2 {
    display: inline-block;
    width: 50%;
    padding: 2% 0;
    text-align: justify;
    margin-right: 8%;
  }

  .añadidoComposicion {
    display: inline-block;
    width: 20%;
    vertical-align: top;
    padding: 10px;
  }

  .imgPDF {
    width: 100%;
  }

  /* .juegoPDF {
    color: black;
    background-color: rgba(161, 161, 161, 0.575);
  }
  .juegoPDF p {
    text-decoration: none;
    color: black;
    margin: 0;
    background-color: rgba(161, 161, 161, 0.575);
  }
  .juegoPDF p:hover,
  .juegoPDF:hover,
  .juegoPDF:hover > .p {
    background-color: rgba(161, 161, 161, 0.74);
  } */

  .filaComposicion {
    display: flex;
    margin-bottom: 20px;
    margin-left: 20%;
    width: 60%;
  }
  .textComposicion {
    text-align: center;
  }
  .aptDIzq {
    background-image: linear-gradient(to right, #ffffff, #a4bfd6);
    border-radius: 13% 0 0 13%;
    opacity: 0.8;
  }
  .filaComposicion:hover > .aptDIzq {
    background-image: linear-gradient(to right, #ffffff, #a4bfd6);
    opacity: 1;
  }

  .aptDDer {
    background-image: linear-gradient(to left, #ffffff, #a4bfd6);
    border-radius: 0 13% 13% 0;
    opacity: 0.8;
  }
  .filaComposicion:hover > .aptDDer {
    background-image: linear-gradient(to left, #ffffff, #a4bfd6);
    opacity: 1;
  }
  .tituloVidComposicion {
    margin: 25px 0;
    font-size: 30px;
    opacity: 1;
  }
  .descComp {
    padding: 6% 10%;
    font-size: 20px;
    font-weight: 600;
    opacity: 1;
  }
  .playerVidComposicion {
    height: 19.6vw !important;
    width: auto !important;
  }
  .videosComposicion {
    background-image: url("../multimedia/fondoDiseño.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    padding: 50px 0;
    position: relative;
  }
  .apartadoComposicion {
    width: 50%;
    display: inline-block;
    position: relative;
    height: 19.6vw !important;
  }
}

@media screen and (min-width: 1595px) {
  .CompCel {
    display: none !important;
  }
  .bannerHomeC {
    background-image: url("../multimedia/ondas.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: 50%;
    position: relative;
  }
  .textoBanner {
    width: 100%;
    padding: 80px 0;
    color: white;
    position: relative;
  }
  .nombreHome {
    font-size: 66px;
    margin: 0px;
    text-align: center;
  }

  .audiosComposicion {
    background-image: url("../multimedia/fondoComp.PNG");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    padding: 40px 0;
    position: relative;
  }
  .tipoComposicion {
    padding-top: 20px;
    margin: 0;
    font-size: 25px;
  }
  .waveComp {
    width: 90%;
    margin: 0 auto !important;
    display: block;
    margin-bottom: 20px;
  }
  .waveComp div div div svg,
  .waveComp div div div span {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .contenidoComposicion {
    background-color: white;
    border-radius: 10% 10% 20% 20%;
    width: 60%;
    margin: 0 20%;
    margin-bottom: 1.5%;
    text-align: center;
    position: relative;
    display: inline-block;
  }
  .textoComposicion {
    padding-bottom: 20px;
  }

  .descComposicion {
    font-size: 20px;
  }
  .musicPlaying {
    font-size: 30px;
  }
  .volumeIcon {
    margin-right: 3%;
    font-size: 30px;
  }
  .volumeInputComp {
    width: 20%;
    vertical-align: super;
  }
  .ComposicionMasterIcons {
    display: inline-block;
    text-align: right;
    margin-left: 38%;
  }
  .ComposicionMasterIcons2 {
    margin-left: 3%;
    width: 50%;
    display: inline-block;
    text-align: left;
  }

  canvas {
    width: 100%;
  }

  .abajoComposicion {
    border-top: 2px black solid;
    background-image: linear-gradient(to bottom, #ffffff, #a4bfd6);
    border-radius: 0 0 15% 15%;
  }

  .textoComposicion {
    display: inline-block;
    width: 50%;
    margin-top: 5%;
    text-align: justify;
    margin-right: 8%;
  }
  .textoComposicion2 {
    display: inline-block;
    width: 50%;
    padding: 2% 0;
    text-align: justify;
    margin-right: 8%;
  }

  .añadidoComposicion {
    display: inline-block;
    width: 18%;
    vertical-align: top;
    padding: 10px;
  }

  .imgPDF {
    width: 100%;
  }

  /* .juegoPDF {
    color: black;
    background-color: rgba(161, 161, 161, 0.575);
  }
  .juegoPDF p {
    text-decoration: none;
    color: black;
    margin: 0;
    background-color: rgba(161, 161, 161, 0.575);
  }
  .juegoPDF p:hover,
  .juegoPDF:hover,
  .juegoPDF:hover > .p {
    background-color: rgba(161, 161, 161, 0.74);
  } */

  .filaComposicion {
    display: flex;
    margin-bottom: 20px;
    margin-left: 20%;
    width: 60%;
  }
  .textComposicion {
    text-align: center;
  }
  .aptDIzq {
    background-image: linear-gradient(to right, #ffffff, #a4bfd6);
    border-radius: 13% 0 0 13%;
  }

  .aptDDer {
    background-image: linear-gradient(to left, #ffffff, #a4bfd6);
    border-radius: 0 13% 13% 0;
  }
  .tituloVidComposicion {
    margin: 25px 0;
    font-size: 30px;
    opacity: 1;
  }
  .descComp {
    padding: 6% 10%;
    font-size: 20px;
    font-weight: 600;
    opacity: 1;
  }
  .playerVidComposicion {
    height: 19.6vw !important;
    width: auto !important;
  }
  .videosComposicion {
    background-image: url("../multimedia/fondoDiseño.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    padding: 50px 0;
    position: relative;
  }
  .apartadoComposicion {
    width: 50%;
    display: inline-block;
    position: relative;
    height: 19.6vw !important;
  }
}

@media screen and (max-width: 600px) {
  .bannerProx {
    background-image: url("../multimedia/fondoMix.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: bottom;
    position: relative;
    height: 90vh;
  }
  .textoBannerProx {
    width: 100%;
    padding: 250px 0;
    color: white;
    position: relative;
  }
  .Prox {
    font-size: 50px;
    margin: 0px;
    text-align: center;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1199px) {
  .bannerProx {
    background-image: url("../multimedia/fondoMix.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: bottom;
    position: relative;
    height: 92vh;
  }
  .textoBannerProx {
    width: 100%;
    padding: 250px 0;
    color: white;
    position: relative;
  }
  .Prox {
    font-size: 100px;
    margin: 0px;
    text-align: center;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1595px) {
  .bannerProx {
    background-image: url("../multimedia/fondoMix.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: bottom;
    position: relative;
    height: 92vh;
  }
  .textoBannerProx {
    width: 100%;
    padding: 250px 0;
    color: white;
    position: relative;
  }
  .Prox {
    font-size: 150px;
    margin: 0px;
    text-align: center;
  }
}
@media screen and (min-width: 1595px) {
  .bannerProx {
    background-image: url("../multimedia/fondoMix.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    background-position: bottom;
    position: relative;
    height: 90vh;
  }
  .textoBannerProx {
    width: 100%;
    padding: 250px 0;
    color: white;
    position: relative;
  }
  .Prox {
    font-size: 150px;
    margin: 0px;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .naveCel {
    display: none;
  }
  .bannerHomeD {
    background-image: url("../multimedia/fondoDiseno.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    position: relative;
  }
  .textoBanner {
    width: 100%;
    padding: 40px 0;
    color: white;
    position: relative;
  }
  .naveCel .nombreHome {
    font-size: 40px;
    margin: 0px;
    text-align: center;
  }

  .videosDiseno {
    background-image: url("../multimedia/fondoDiseño.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    padding: 20px 0;
    position: relative;
  }
  .contenidoDiseno {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .apartadoDiseno {
    width: 100%;
    position: relative;
  }
  .filtro {
    background-color: white;
    opacity: 0.2;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;
  }
  .filaDiseno {
    margin-bottom: 15px;
  }
  .textoDiseno {
    text-align: center;
  }

  .aptD {
    background-image: linear-gradient(to top, #ffffff, #d6afa4);
    border-radius: 13% 13% 0 0;
    opacity: 0.8;
  }
  .tituloVidDiseno {
    margin: 0;
    margin-bottom: 25px 0;
    padding-top: 4%;
    font-size: 25px;

    opacity: 1;
  }
  .descDiseno {
    padding: 6% 8%;
    font-size: 20px;
    font-weight: 600;
    opacity: 1;
    margin: 0;
  }
  .playerVidDiseno {
    height: 50.625vw !important;
    width: auto !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1199px) {
  .naveCel {
    display: none;
  }
  .bannerHomeD {
    background-image: url("../multimedia/fondoDiseno.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    position: relative;
  }
  .textoBanner {
    width: 100%;
    padding: 40px 0;
    color: white;
    position: relative;
  }
  .nombreHome {
    font-size: 50px;
    margin: 0px;
    text-align: center;
  }

  .videosDiseno {
    background-image: url("../multimedia/fondoDiseño.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    padding: 20px 0;
    position: relative;
  }
  .contenidoDiseno {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .apartadoDiseno {
    width: 100%;
    position: relative;
  }
  .filtro {
    background-color: white;
    opacity: 0.2;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;
  }
  .filaDiseno {
    margin-bottom: 25px;
  }
  .textoDiseno {
    text-align: center;
  }

  .aptD {
    background-image: linear-gradient(to top, #ffffff, #d6afa4);
    border-radius: 13% 13% 0 0;
    opacity: 0.8;
  }
  .tituloVidDiseno {
    margin: 0;
    margin-bottom: 25px 0;
    padding-top: 4%;
    font-size: 40px;

    opacity: 1;
  }
  .descDiseno {
    padding: 4% 10%;
    font-size: 30px;
    font-weight: 600;
    opacity: 1;
    margin: 0;
  }
  .playerVidDiseno {
    height: 50.625vw !important;
    width: auto !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1595px) {
  .naveCel {
    display: none;
  }
  .bannerHomeD {
    background-image: url("../multimedia/fondoDiseno.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    position: relative;
  }
  .textoBanner {
    width: 100%;
    padding: 80px 0;
    color: white;
    position: relative;
  }
  .nombreHome {
    font-size: 66px;
    margin: 0px;
    text-align: center;
  }

  .videosDiseno {
    background-image: url("../multimedia/fondoDiseño.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    padding: 50px 0;
    position: relative;
  }
  .contenidoDiseno {
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
  }
  .apartadoDiseno {
    width: 50%;
    display: inline-block;
    position: relative;
    height: 22.3vw !important;
  }
  .filtro {
    background-color: white;
    opacity: 0.2;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;
  }
  .filaDiseno {
    display: flex;
    margin-bottom: 20px;
  }
  .textoDiseno {
    text-align: center;
  }
  .aptDIzq {
    background-image: linear-gradient(to right, #ffffff, #d6afa4);
    border-radius: 13% 0 0 13%;
    opacity: 0.8;
  }
  .filaDiseno:hover > .aptDIzq {
    background-image: linear-gradient(to right, #ffffff, #d6afa4);
    opacity: 1;
  }

  .aptDDer {
    background-image: linear-gradient(to left, #ffffff, #d6afa4);
    border-radius: 0 13% 13% 0;
    opacity: 0.8;
  }
  .filaDiseno:hover > .aptDDer {
    background-image: linear-gradient(to left, #ffffff, #d6afa4);
    opacity: 1;
  }
  .tituloVidDiseno {
    margin: 15px 0;
    font-size: 29px;
    opacity: 1;
  }
  .descDiseno {
    padding: 4% 8%;
    font-size: 22px;
    font-weight: 600;
    opacity: 1;
  }
  .playerVidDiseno {
    height: 22.3vw !important;
    width: auto !important;
  }

  /* .videosDiseno2 {
    background-image: url("../multimedia/shin chan.png");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    padding: 50px 0;
    position: relative;
  }
  .contenidoDiseno2 {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .apartadoDiseno2 {
    position: relative;
    margin-right: 0 !important;
    padding: 0 5px;
  }
  .filaDiseno2 {
    display: inline-block;
    width: 33%;
  }
  .textoDiseno2 {
    text-align: center;
  }
  .tituloVidDisno2 {
    font-size: 20px;
    margin: 0;
  }
  .descDiseno2 {
    margin: 0;
    padding: 0 5px;
  }
  .playerVidDiseno2 {
    height: 16.8vw !important;
    width: auto !important;
  } */
}

@media screen and (min-width: 1595px) {
  .naveCel {
    display: none;
  }
  .bannerHomeD {
    background-image: url("../multimedia/fondoDiseno.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    position: relative;
  }
  .textoBanner {
    width: 100%;
    padding: 80px 0;
    color: white;
    position: relative;
  }
  .nombreHome {
    font-size: 66px;
    margin: 0px;
    text-align: center;
  }

  .videosDiseno {
    background-image: url("../multimedia/fondoDiseño.jpg");
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
    padding: 50px 0;
    position: relative;
  }
  .contenidoDiseno {
    width: 70%;
    padding-left: 15%;
    padding-right: 15%;
  }
  .apartadoDiseno {
    width: 50%;
    display: inline-block;
    position: relative;
    height: 19.6vw !important;
  }
  .filtro {
    background-color: white;
    opacity: 0.2;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;
  }
  .filaDiseno {
    display: flex;
    margin-bottom: 20px;
  }
  .textoDiseno {
    text-align: center;
  }
  .aptDIzq {
    background-image: linear-gradient(to right, #ffffff, #d6afa4);
    border-radius: 13% 0 0 13%;
    opacity: 0.8;
  }
  .filaDiseno:hover > .aptDIzq {
    background-image: linear-gradient(to right, #ffffff, #d6afa4);
    opacity: 1;
  }

  .aptDDer {
    background-image: linear-gradient(to left, #ffffff, #d6afa4);
    border-radius: 0 13% 13% 0;
    opacity: 0.8;
  }
  .filaDiseno:hover > .aptDDer {
    background-image: linear-gradient(to left, #ffffff, #d6afa4);
    opacity: 1;
  }
  .tituloVidDiseno {
    margin: 25px 0;
    font-size: 40px;
    opacity: 1;
  }
  .descDiseno {
    padding: 6% 10%;
    font-size: 25px;
    font-weight: 600;
    opacity: 1;
  }
  .playerVidDiseno {
    height: 19.6vw !important;
    width: auto !important;
  }
}

@media screen and (max-width: 600px) {
  .divVideoAbout {
    width: 100%;
    position: absolute;
    margin-top: 10px;
    padding-bottom: 50px;
  }
  .videoYTAbout {
    width: auto !important;
    height: 50.5vw !important;
  }
  .AboutMe {
    box-sizing: border-box;
  }
  .AboutMe html {
    width: 100%;
    height: 100%;
  }
  .AboutMe body {
    margin: 0;
    padding: 0;
    font: 400 16px/24px Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #212121;
    text-align: left;
  }
  .AboutMe a,
  .AboutMe a:active,
  .AboutMe a:link,
  .AboutMe a:visited {
    text-decoration: none;
    color: #039be5;
    transition: color 0.25s linear;
  }
  .AboutMe h1,
  h2 {
    font-weight: 500;
    margin: 0;
  }
  .AboutMe h1 {
    font-size: 24px;
  }
  .AboutMe h2 {
    font-size: 16px;
  }
  .AboutMe p {
    margin: 0px;
  }
  .AboutMe .no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .AboutMe .profile-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }

  .AboutMe .profile-header {
    background-color: #263238;
    padding: 10px;
    color: #fff;
    text-align: center;
  }
  .AboutMe .profile-bio {
    padding: 20px;
    overflow: hidden;
    background-color: #fafafa;
  }
  .AboutMe .profile-avatar {
    display: inline-block;
    text-align: center;
    position: relative;
    height: 180px;
    width: 180px;
    overflow: hidden;
    margin: 0;
    border-radius: 50%;
    border: 10px solid rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
  }
  .AboutMe .profile-avatar:hover {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  .AboutMe .profile-avatar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .AboutMe .profile-avatar img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.3s ease;
  }
  .AboutMe .profile-avatar:hover img {
    width: 130%;
  }

  .AboutMe #profile-bio-wrapper {
    position: absolute;
    top: 95.5%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1),
      0px -1px 6px rgba(0, 0, 0, 0.12);
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .AboutMe p.short-description {
    margin-bottom: 20px;
    text-align: justify;
  }

  .AboutMe #show-more-icon {
    display: inline-block;
    text-align: center;
    font-size: 50px;
    color: #263238;
    position: absolute;
    top: -5px;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
    height: 35px;
    width: 35px;
  }

  .AboutMe #show-more-icon .fa {
    line-height: 48px;
    transition: transform 0.4s ease-in-out;
  }

  .AboutMe .social-list {
    background-color: #eee;
    border-bottom: 2px solid #eee;
  }

  .AboutMe .social-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: table;
    width: 100%;
    text-align: center;
  }
  .AboutMe li.bio-item {
    display: table-cell;
  }
  .AboutMe li.bio-item:hover {
    background-color: #e0e0e0;
  }
  .AboutMe li.bio-item:nth-child(2) {
    padding-right: 0px;
    border-right: 10px solid transparent;
  }
  .AboutMe li.bio-item:nth-child(3) {
    border-left: 10px solid transparent;
  }

  .AboutMe li.bio-item a {
    color: #616161;
    display: block;
    /* padding: 16px 0; */
  }

  /* #####################################
	  ALL THE REST ############################
	  ######################################## */

  .AboutMe .mail-wrapper {
    position: absolute;
    left: 50%;
    top: 43% !important;
    transform: translateX(-50%) translateY(-60%);
    width: 90vw !important;
    border-radius: 50%;
    background-color: #630886;
    box-shadow: 0 -2px 25px 0 rgba(0, 0, 0, 0.15),
      0 13px 25px 0 rgba(0, 0, 0, 0.3);
    opacity: 0;
    z-index: -1;
    animation: init 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards,
      moveDown 1s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards,
      moveUp 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards,
      materia 0.4s ease-in-out forwards;
    animation-delay: 0s, 0.7s, 1.7s, 2.4s;
    will-change: auto;
  }

  .AboutMe .mail-content {
    opacity: 0;
    box-sizing: border-box;
    color: #000;
    animation: moveInCenter 0.6s ease forwards;
    animation-delay: 2.7s;
  }

  .AboutMe .bottom-ball-fill {
    position: fixed;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
    height: 0px;
    width: 0px;
    border-radius: 50%;
    background-color: #607d8b;
    z-index: 0;
    animation: puff 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards,
      borderRadius 0.2s linear forwards;
    animation-delay: 1.8s, 2.3s;
  }

  /* Animations */
  @keyframes init {
    0% {
      width: 0px;
      height: 0px;
      margin-top: 35px;
    }
    100% {
      width: 56px;
      height: 56px;
      opacity: 1;
      z-index: 2;
    }
  }
  @keyframes moveDown {
    0% {
      top: 50%;
    }
    50% {
      top: 40%;
    }
    100% {
      top: 100%;
      margin-top: 0;
    }
  }
  @keyframes moveUp {
    0% {
      top: 100%;
      background: #607d8b;
    }
    50% {
      top: 40%;
    }
    100% {
      top: 50%;
      background: #fff;
    }
  }
  @keyframes materia {
    0% {
      background: #fff;
    }
    100% {
      width: 300px;
      height: 410px;
      background: #fff;
      border-radius: 10px;
    }
  }

  @keyframes puff {
    0% {
      top: 100%;
      height: 50px;
      width: 50px;
      transform: translate(-50%, -50%) scale(0);
    }
    100% {
      top: 50%;
      height: 50px;
      width: 50px;
      transform: translate(-50%, -50%) scale(50);
      z-index: 1;
    }
  }
  @keyframes borderRadius {
    0% {
      border-radius: 50%;
    }
    100% {
      border-radius: 0px;
    }
  }

  @keyframes moveInCenter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .bio-extra::-webkit-scrollbar-track {
    background-color: #f4f4f4;
  }
  .bio-extra::-webkit-scrollbar {
    width: 6px;
    background: #f4f4f4;
  }
  .bio-extra::-webkit-scrollbar-thumb {
    background: #dad7d7;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1199px) {
  .divVideoAbout {
    width: 130%;
    position: absolute;
    left: -15%;
    margin-top: 10px;
    padding-bottom: 50px;
  }
  .videoYTAbout {
    width: auto !important;
    height: 44vw !important;
  }
  .AboutMe {
    box-sizing: border-box;
  }
  .AboutMe html {
    width: 100%;
    height: 100%;
  }
  .AboutMe body {
    margin: 0;
    padding: 0;
    font: 400 16px/24px Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #212121;
    text-align: left;
  }
  .AboutMe a,
  .AboutMe a:active,
  .AboutMe a:link,
  .AboutMe a:visited {
    text-decoration: none;
    color: #039be5;
    transition: color 0.25s linear;
  }
  .AboutMe h1,
  h2 {
    font-weight: 500;
    margin: 0;
  }
  .AboutMe h1 {
    font-size: 30px;
  }
  .AboutMe h2 {
    font-size: 25px;
  }
  .AboutMe p {
    margin: 0px;
  }
  .AboutMe .no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .AboutMe .profile-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }

  .AboutMe .profile-header {
    background-color: #263238;
    padding: 10px;
    color: #fff;
    text-align: center;
  }
  .AboutMe .profile-bio {
    padding: 20px;
    overflow: hidden;
    background-color: #fafafa;
  }
  .AboutMe .profile-avatar {
    display: inline-block;
    text-align: center;
    position: relative;
    height: 180px;
    width: 180px;
    overflow: hidden;
    margin: 10px 0;
    border-radius: 50%;
    border: 10px solid rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
  }
  .AboutMe .profile-avatar:hover {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  .AboutMe .profile-avatar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .AboutMe .profile-avatar img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.3s ease;
  }
  .AboutMe .profile-avatar:hover img {
    width: 130%;
  }

  .AboutMe #profile-bio-wrapper {
    position: absolute;
    top: 96%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1),
      0px -1px 6px rgba(0, 0, 0, 0.12);
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .AboutMe p.short-description {
    margin-bottom: 20px;
    text-align: justify;
    font-size: 20px;
  }

  .AboutMe #show-more-icon {
    display: inline-block;
    text-align: center;
    font-size: 50px;
    color: #263238;
    position: absolute;
    top: -5px;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
    height: 35px;
    width: 35px;
  }

  .AboutMe #show-more-icon .fa {
    line-height: 48px;
    transition: transform 0.4s ease-in-out;
  }

  .AboutMe .social-list {
    background-color: #eee;
    border-bottom: 2px solid #eee;
  }

  .AboutMe .social-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: table;
    width: 100%;
    text-align: center;
  }
  .AboutMe li.bio-item {
    display: table-cell;
  }
  .AboutMe li.bio-item:hover {
    background-color: #e0e0e0;
  }
  .AboutMe li.bio-item:nth-child(2) {
    padding-right: 0px;
    border-right: 10px solid transparent;
  }
  .AboutMe li.bio-item:nth-child(3) {
    border-left: 10px solid transparent;
  }

  .AboutMe li.bio-item a {
    color: #616161;
    display: block;
    /* padding: 16px 0; */
  }

  /* #####################################
	  ALL THE REST ############################
	  ######################################## */

  .AboutMe .mail-wrapper {
    position: absolute;
    left: 50%;
    top: 35% !important;
    transform: translateX(-50%) translateY(-60%);
    width: 60vw !important;
    border-radius: 50%;
    background-color: #630886;
    box-shadow: 0 -2px 25px 0 rgba(0, 0, 0, 0.15),
      0 13px 25px 0 rgba(0, 0, 0, 0.3);
    opacity: 0;
    z-index: -1;
    animation: init 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards,
      moveDown 1s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards,
      moveUp 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards,
      materia 0.4s ease-in-out forwards;
    animation-delay: 0s, 0.7s, 1.7s, 2.4s;
    will-change: auto;
  }

  .AboutMe .mail-content {
    opacity: 0;
    box-sizing: border-box;
    color: #000;
    animation: moveInCenter 0.6s ease forwards;
    animation-delay: 2.7s;
  }

  .AboutMe .bottom-ball-fill {
    position: fixed;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
    height: 0px;
    width: 0px;
    border-radius: 50%;
    background-color: #607d8b;
    z-index: 0;
    animation: puff 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards,
      borderRadius 0.2s linear forwards;
    animation-delay: 1.8s, 2.3s;
  }

  /* Animations */
  @keyframes init {
    0% {
      width: 0px;
      height: 0px;
      margin-top: 35px;
    }
    100% {
      width: 56px;
      height: 56px;
      opacity: 1;
      z-index: 2;
    }
  }
  @keyframes moveDown {
    0% {
      top: 50%;
    }
    50% {
      top: 40%;
    }
    100% {
      top: 100%;
      margin-top: 0;
    }
  }
  @keyframes moveUp {
    0% {
      top: 100%;
      background: #607d8b;
    }
    50% {
      top: 40%;
    }
    100% {
      top: 50%;
      background: #fff;
    }
  }
  @keyframes materia {
    0% {
      background: #fff;
    }
    100% {
      width: 300px;
      height: 410px;
      background: #fff;
      border-radius: 10px;
    }
  }

  @keyframes puff {
    0% {
      top: 100%;
      height: 50px;
      width: 50px;
      transform: translate(-50%, -50%) scale(0);
    }
    100% {
      top: 50%;
      height: 50px;
      width: 50px;
      transform: translate(-50%, -50%) scale(50);
      z-index: 1;
    }
  }
  @keyframes borderRadius {
    0% {
      border-radius: 50%;
    }
    100% {
      border-radius: 0px;
    }
  }

  @keyframes moveInCenter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .bio-extra::-webkit-scrollbar-track {
    background-color: #f4f4f4;
  }
  .bio-extra::-webkit-scrollbar {
    width: 6px;
    background: #f4f4f4;
  }
  .bio-extra::-webkit-scrollbar-thumb {
    background: #dad7d7;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1595px) {
  .divVideoAbout {
    width: 130%;
    position: absolute;
    left: -15%;
    margin-top: 10px;
    padding-bottom: 50px;
  }
  .videoYTAbout {
    width: auto !important;
    height: 29.3vw !important;
  }
  .AboutMe {
    box-sizing: border-box;
  }
  .AboutMe html {
    width: 100%;
    height: 100%;
  }
  .AboutMe body {
    margin: 0;
    padding: 0;
    font: 400 16px/24px Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #212121;
    text-align: left;
  }
  .AboutMe a,
  .AboutMe a:active,
  .AboutMe a:link,
  .AboutMe a:visited {
    text-decoration: none;
    color: #039be5;
    transition: color 0.25s linear;
  }
  .AboutMe h1,
  h2 {
    font-weight: 500;
    margin: 0;
  }
  .AboutMe h1 {
    font-size: 24px;
  }
  .AboutMe h2 {
    font-size: 16px;
  }
  .AboutMe p {
    margin: 0px;
  }
  .AboutMe .no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .AboutMe .profile-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }

  .AboutMe .profile-header {
    background-color: #263238;
    padding: 10px;
    color: #fff;
    text-align: center;
  }
  .AboutMe .profile-bio {
    padding: 20px;
    overflow: hidden;
    background-color: #fafafa;
  }
  .AboutMe .profile-avatar {
    display: inline-block;
    text-align: center;
    position: relative;
    height: 180px;
    width: 180px;
    overflow: hidden;
    margin: 10px 30px 10px 30px;
    border-radius: 50%;
    border: 10px solid rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
  }
  .AboutMe .profile-avatar:hover {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  .AboutMe .profile-avatar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .AboutMe .profile-avatar img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.3s ease;
  }
  .AboutMe .profile-avatar:hover img {
    width: 130%;
  }

  .AboutMe #profile-bio-wrapper {
    position: absolute;
    top: 95.5%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1),
      0px -1px 6px rgba(0, 0, 0, 0.12);
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .AboutMe p.short-description {
    margin-bottom: 20px;
    text-align: justify;
  }

  .AboutMe #show-more-icon {
    display: inline-block;
    text-align: center;
    font-size: 50px;
    color: #263238;
    position: absolute;
    top: -5px;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
    height: 35px;
    width: 35px;
  }

  .AboutMe #show-more-icon .fa {
    line-height: 48px;
    transition: transform 0.4s ease-in-out;
  }

  .AboutMe .social-list {
    background-color: #eee;
    border-bottom: 2px solid #eee;
  }

  .AboutMe .social-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: table;
    width: 100%;
    text-align: center;
  }
  .AboutMe li.bio-item {
    display: table-cell;
  }
  .AboutMe li.bio-item:hover {
    background-color: #e0e0e0;
  }
  .AboutMe li.bio-item:nth-child(2) {
    padding-right: 0px;
    border-right: 10px solid transparent;
  }
  .AboutMe li.bio-item:nth-child(3) {
    border-left: 10px solid transparent;
  }

  .AboutMe li.bio-item a {
    color: #616161;
    display: block;
  }

  /* #####################################
	  ALL THE REST ############################
	  ######################################## */

  .AboutMe .mail-wrapper {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translateX(-50%) translateY(-60%);
    width: 40vw !important;
    border-radius: 50%;
    background-color: #630886;
    box-shadow: 0 -2px 25px 0 rgba(0, 0, 0, 0.15),
      0 13px 25px 0 rgba(0, 0, 0, 0.3);
    opacity: 0;
    z-index: -1;
    animation: init 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards,
      moveDown 1s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards,
      moveUp 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards,
      materia 0.4s ease-in-out forwards;
    animation-delay: 0s, 0.7s, 1.7s, 2.4s;
    will-change: auto;
  }

  .AboutMe .mail-content {
    opacity: 0;
    box-sizing: border-box;
    color: #000;
    animation: moveInCenter 0.6s ease forwards;
    animation-delay: 2.7s;
  }

  .AboutMe .bottom-ball-fill {
    position: fixed;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
    height: 0px;
    width: 0px;
    border-radius: 50%;
    background-color: #607d8b;
    z-index: 0;
    animation: puff 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards,
      borderRadius 0.2s linear forwards;
    animation-delay: 1.8s, 2.3s;
  }

  /* Animations */
  @keyframes init {
    0% {
      width: 0px;
      height: 0px;
      margin-top: 35px;
    }
    100% {
      width: 56px;
      height: 56px;
      opacity: 1;
      z-index: 2;
    }
  }
  @keyframes moveDown {
    0% {
      top: 50%;
    }
    50% {
      top: 40%;
    }
    100% {
      top: 100%;
      margin-top: 0;
    }
  }
  @keyframes moveUp {
    0% {
      top: 100%;
      background: #607d8b;
    }
    50% {
      top: 40%;
    }
    100% {
      top: 50%;
      background: #fff;
    }
  }
  @keyframes materia {
    0% {
      background: #fff;
    }
    100% {
      width: 300px;
      height: 410px;
      background: #fff;
      border-radius: 10px;
    }
  }

  @keyframes puff {
    0% {
      top: 100%;
      height: 50px;
      width: 50px;
      transform: translate(-50%, -50%) scale(0);
    }
    100% {
      top: 50%;
      height: 50px;
      width: 50px;
      transform: translate(-50%, -50%) scale(50);
      z-index: 1;
    }
  }
  @keyframes borderRadius {
    0% {
      border-radius: 50%;
    }
    100% {
      border-radius: 0px;
    }
  }

  @keyframes moveInCenter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .bio-extra::-webkit-scrollbar-track {
    background-color: #f4f4f4;
  }
  .bio-extra::-webkit-scrollbar {
    width: 6px;
    background: #f4f4f4;
  }
  .bio-extra::-webkit-scrollbar-thumb {
    background: #dad7d7;
  }
}

@media screen and (min-width: 1595px) {
  .divVideoAbout {
    width: 150%;
    position: absolute;
    left: -25%;
    margin-top: 10px;
    padding-bottom: 50px;
  }
  .videoYTAbout {
    width: auto !important;
    height: 25.3vw !important;
  }
  .AboutMe {
    box-sizing: border-box;
  }
  .AboutMe html {
    width: 100%;
    height: 100%;
  }
  .AboutMe body {
    margin: 0;
    padding: 0;
    font: 400 16px/24px Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #212121;
    text-align: left;
  }
  .AboutMe a,
  .AboutMe a:active,
  .AboutMe a:link,
  .AboutMe a:visited {
    text-decoration: none;
    color: #039be5;
    transition: color 0.25s linear;
  }
  .AboutMe h1,
  h2 {
    font-weight: 500;
    margin: 0;
  }
  .AboutMe h1 {
    font-size: 24px;
  }
  .AboutMe h2 {
    font-size: 16px;
  }
  .AboutMe p {
    margin: 0px;
  }
  .AboutMe .no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .AboutMe .profile-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }

  .AboutMe .profile-header {
    background-color: #263238;
    padding: 10px;
    color: #fff;
    text-align: center;
  }
  .AboutMe .profile-bio {
    padding: 20px;
    overflow: hidden;
    background-color: #fafafa;
  }
  .AboutMe .profile-avatar {
    display: inline-block;
    text-align: center;
    position: relative;
    height: 180px;
    width: 180px;
    overflow: hidden;
    margin: 10px 30px 10px 30px;
    border-radius: 50%;
    border: 10px solid rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
  }
  .AboutMe .profile-avatar:hover {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  .AboutMe .profile-avatar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .AboutMe .profile-avatar img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.3s ease;
  }
  .AboutMe .profile-avatar:hover img {
    width: 130%;
  }

  .AboutMe #profile-bio-wrapper {
    position: absolute;
    top: 95.5%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1),
      0px -1px 6px rgba(0, 0, 0, 0.12);
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .AboutMe p.short-description {
    margin-bottom: 20px;
    text-align: justify;
  }

  .AboutMe #show-more-icon {
    display: inline-block;
    text-align: center;
    font-size: 50px;
    color: #263238;
    position: absolute;
    top: -5px;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
    height: 35px;
    width: 35px;
  }

  .AboutMe #show-more-icon .fa {
    line-height: 48px;
    transition: transform 0.4s ease-in-out;
  }

  .AboutMe .social-list {
    background-color: #eee;
    border-bottom: 2px solid #eee;
  }

  .AboutMe .social-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: table;
    width: 100%;
    text-align: center;
  }
  .AboutMe li.bio-item {
    display: table-cell;
  }
  .AboutMe li.bio-item:hover {
    background-color: #e0e0e0;
  }
  .AboutMe li.bio-item:nth-child(2) {
    padding-right: 0px;
    border-right: 10px solid transparent;
  }
  .AboutMe li.bio-item:nth-child(3) {
    border-left: 10px solid transparent;
  }

  .AboutMe li.bio-item a {
    color: #616161;
    display: block;
    /* padding: 16px 0; */
  }

  /* #####################################
		ALL THE REST ############################
		######################################## */

  .AboutMe .mail-wrapper {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translateX(-50%) translateY(-80%);
    width: 30vw !important;
    border-radius: 50%;
    background-color: #630886;
    box-shadow: 0 -2px 25px 0 rgba(0, 0, 0, 0.15),
      0 13px 25px 0 rgba(0, 0, 0, 0.3);
    opacity: 0;
    z-index: -1;
    animation: init 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards,
      moveDown 1s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards,
      moveUp 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards,
      materia 0.4s ease-in-out forwards;
    animation-delay: 0s, 0.7s, 1.7s, 2.4s;
    will-change: auto;
  }

  .AboutMe .mail-content {
    opacity: 0;
    box-sizing: border-box;
    color: #000;
    animation: moveInCenter 0.6s ease forwards;
    animation-delay: 2.7s;
  }

  .AboutMe .bottom-ball-fill {
    margin-bottom: 100px;
    position: fixed;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
    height: 0px;
    width: 0px;
    border-radius: 50%;
    background-color: #607d8b;
    z-index: 0;
    animation: puff 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards,
      borderRadius 0.2s linear forwards;
    animation-delay: 1.8s, 2.3s;
  }

  /* Animations */
  @keyframes init {
    0% {
      width: 0px;
      height: 0px;
      margin-top: 35px;
    }
    100% {
      width: 56px;
      height: 56px;
      opacity: 1;
      z-index: 2;
    }
  }
  @keyframes moveDown {
    0% {
      top: 50%;
    }
    50% {
      top: 40%;
    }
    100% {
      top: 100%;
      margin-top: 0;
    }
  }
  @keyframes moveUp {
    0% {
      top: 100%;
      background: #607d8b;
    }
    50% {
      top: 40%;
    }
    100% {
      top: 50%;
      background: #fff;
    }
  }
  @keyframes materia {
    0% {
      background: #fff;
    }
    100% {
      width: 300px;
      height: 410px;
      background: #fff;
      border-radius: 10px;
    }
  }

  @keyframes puff {
    0% {
      top: 100%;
      height: 50px;
      width: 50px;
      transform: translate(-50%, -50%) scale(0);
    }
    100% {
      top: 50%;
      height: 50px;
      width: 50px;
      transform: translate(-50%, -50%) scale(50);
      z-index: 1;
    }
  }
  @keyframes borderRadius {
    0% {
      border-radius: 50%;
    }
    100% {
      border-radius: 0px;
    }
  }

  @keyframes moveInCenter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .bio-extra::-webkit-scrollbar-track {
    background-color: #f4f4f4;
  }
  .bio-extra::-webkit-scrollbar {
    width: 6px;
    background: #f4f4f4;
  }
  .bio-extra::-webkit-scrollbar-thumb {
    background: #dad7d7;
  }
}

@media screen and (max-width: 600px) {
  /*Video*/
  .videoHome {
    width: 100%;
    height: 91.5vh;
    background-image: url("../multimedia/fondoHome.jpg");
    background-color: black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .vidWidth {
    width: 98%;
    margin: 0 auto;
  }
  .videoYTHome {
    width: auto !important;
    height: 55.125vw !important;
  }
  .nombreHomepage {
    margin: 0;
    text-align: center;
    font-size: 30px;
    color: rgb(199, 1, 1);
    padding-top: 4%;
    text-transform: uppercase;
  }
  .descHomepage {
    text-align: center;
    font-size: 20px;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1199px) {
  /*Video*/
  .videoHome {
    width: 100%;
    height: 91.5vh;
    background-image: url("../multimedia/fondoHome.jpg");
    background-color: black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .vidWidth {
    width: 98%;
    margin: 0 auto;
  }
  .videoYTHome {
    width: auto !important;
    height: 55.125vw !important;
  }
  .nombreHomepage {
    margin: 0;
    text-align: center;
    font-size: 30px;
    color: rgb(199, 1, 1);
    padding-top: 4%;
    text-transform: uppercase;
  }
  .descHomepage {
    text-align: center;
    font-size: 20px;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 15px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1595px) {
  /*Mi trabajo*/
  .trabajoHome {
    padding-top: 20px;
    padding-bottom: 15px;
  }
  .MiTrabajo {
    text-align: center;
    margin: 21px;
  }
  .textoTrabajo {
    font-size: 45px;
    margin: 0px;
  }
  .fotosTrabajo {
    width: 100%;
    padding-top: 10px;
    display: flex;
    animation: slideFromLeft 1.5s ease-out;
  }
  .apartadoFoto {
    width: 32.8%;
    text-align: center;
    display: inline-block;
  }
  .apt1 {
    padding-left: 0.4%;
  }
  .apt2 {
    padding: 0 0.4%;
  }
  .apt3 {
    padding-right: 0.4%;
  }
  .fotoHome {
    width: 100%;
  }
  .nombreTrabajo {
    margin: 10px 0;
  }
  .descTrabajo {
    margin: 0;
  }
  @keyframes slideFromLeft {
    0% {
      transform: translateX(-120vw);
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      transform: translateX(0vw);
    }
  }

  /*Sobre mi*/
  .fondoSobreMi {
    background-color: white;
    opacity: 0.8;
    position: absolute;
    left: -34%;
    width: 167.4%;
    height: 100%;
  }
  .datosSobreMi {
    width: 60%;
    margin: 0 auto;
    position: relative;
  }
  .miImagen {
    padding-top: 15px;
    text-align: center;
    position: relative;
  }
  .imagenYo {
    width: 33%;
    border-radius: 42%;
  }
  .miNombre {
    text-align: center;
    color: #ff0000;
    position: relative;
  }
  .miNom {
    font-size: 32px;
    font-weight: bold;
  }
  .miDescripcion {
    column-gap: 0px;
    text-align: center;
    position: relative;
    padding-bottom: 15px;
  }
  .miDesc {
    font-size: 22px;
    font-weight: bold;
    line-height: 40px;
  }

  /*CV*/
  .CV {
    background-color: #e96656;
  }
  .CVtexto {
    padding: 60px;
  }
  .textoCV {
    display: inline-block;
    width: 70%;
    animation: CVFromLeft 1s linear;
  }
  .shareCV {
    font-size: 24px;
    color: white;
    font-weight: 400;
  }
  .CVboton {
    display: inline-block;
    width: 30%;
    text-align: right;
    animation: CVFromRight 1s linear;
  }
  .CVbtn {
    font-size: 14px;
    color: white;
    text-transform: uppercase;
    background-color: #db5a4a;
    padding: 15px 25px;
  }
  @keyframes CVFromLeft {
    0% {
      transform: translateX(-80vw);
    }
    100% {
      transform: translateX(0vw);
    }
  }
  @keyframes CVFromRight {
    0% {
      transform: translateX(10vw);
      opacity: 0;
    }
    100% {
      transform: translateX(0vw);
      opacity: 1;
    }
  }

  /*Video*/
  .videoHome {
    width: 100%;
    height: 91.5vh;
    background-image: url("../multimedia/fondoHome.jpg");
    background-color: black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .vidWidth {
    width: 71%;
    margin: 0 auto;
  }
  .videoYTHome {
    width: auto !important;
    height: 40vw !important;
  }
  .nombreHomepage {
    margin: 0;
    text-align: center;
    font-size: 40px;
    color: rgb(199, 1, 1);
    padding-top: 4%;
    text-transform: uppercase;
  }
  .descHomepage {
    text-align: center;
    font-size: 30px;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 15px;
  }
}

@media screen and (min-width: 1595px) {
  /*Mi trabajo*/
  .trabajoHome {
    padding-top: 20px;
    padding-bottom: 15px;
  }
  .MiTrabajo {
    text-align: center;
    margin: 21px;
  }
  .textoTrabajo {
    font-size: 45px;
    margin: 0px;
  }
  .fotosTrabajo {
    width: 100%;
    padding-top: 10px;
    display: flex;
    animation: slideFromLeft 1.5s ease-out;
  }
  .apartadoFoto {
    width: 32.8%;
    text-align: center;
    display: inline-block;
  }
  .apt1 {
    padding-left: 0.4%;
  }
  .apt2 {
    padding: 0 0.4%;
  }
  .apt3 {
    padding-right: 0.4%;
  }
  .fotoHome {
    width: 100%;
  }
  .nombreTrabajo {
    margin: 10px 0;
  }
  .descTrabajo {
    margin: 0;
  }
  @keyframes slideFromLeft {
    0% {
      transform: translateX(-120vw);
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      transform: translateX(0vw);
    }
  }

  /*Sobre mi*/
  .fondoSobreMi {
    background-color: white;
    opacity: 0.8;
    position: absolute;
    left: -34%;
    width: 167.4%;
    height: 100%;
  }
  .datosSobreMi {
    width: 60%;
    margin: 0 auto;
    position: relative;
  }
  .miImagen {
    padding-top: 15px;
    text-align: center;
    position: relative;
  }
  .imagenYo {
    width: 33%;
    border-radius: 42%;
  }
  .miNombre {
    text-align: center;
    color: #ff0000;
    position: relative;
  }
  .miNom {
    font-size: 32px;
    font-weight: bold;
  }
  .miDescripcion {
    column-gap: 0px;
    text-align: center;
    position: relative;
    padding-bottom: 15px;
  }
  .miDesc {
    font-size: 22px;
    font-weight: bold;
    line-height: 40px;
  }

  /*CV*/
  .CV {
    background-color: #e96656;
  }
  .CVtexto {
    padding: 60px;
  }
  .textoCV {
    display: inline-block;
    width: 70%;
    animation: CVFromLeft 1s linear;
  }
  .shareCV {
    font-size: 24px;
    color: white;
    font-weight: 400;
  }
  .CVboton {
    display: inline-block;
    width: 30%;
    text-align: right;
    animation: CVFromRight 1s linear;
  }
  .CVbtn {
    font-size: 14px;
    color: white;
    text-transform: uppercase;
    background-color: #db5a4a;
    padding: 15px 25px;
  }
  @keyframes CVFromLeft {
    0% {
      transform: translateX(-80vw);
    }
    100% {
      transform: translateX(0vw);
    }
  }
  @keyframes CVFromRight {
    0% {
      transform: translateX(10vw);
      opacity: 0;
    }
    100% {
      transform: translateX(0vw);
      opacity: 1;
    }
  }

  /*Video*/
  .videoHome {
    width: 100%;
    height: 90vh;
    background-image: url("../multimedia/fondoHome.jpg");
    background-color: black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .vidWidth {
    width: 58%;
    margin: 0 auto;
  }
  .videoYTHome {
    width: auto !important;
    height: 32.6vw !important;
  }
  .nombreHomepage {
    margin: 0;
    text-align: center;
    font-size: 40px;
    color: rgb(199, 1, 1);
    padding-top: 4%;
    text-transform: uppercase;
  }
  .descHomepage {
    text-align: center;
    font-size: 30px;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 15px;
  }
}
